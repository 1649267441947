import { ConfigProvider, Tabs } from "antd"
import type { TabsProps } from 'antd';


const TabSelectMovieMobile = ({ tabContent, className }: { tabContent: TabsProps['items'], className?: string }) => {

  return <ConfigProvider
    theme={{
      components: {
        Tabs: {
          itemSelectedColor: "#F3EA28",
          itemActiveColor: "#F3EA28",
          itemHoverColor: "#F3EA28",
          inkBarColor: "#F3EA28"
        },
      },
      token: {
        colorText: "white"
      }
    }}
  >
    <section className={`tabs-movie-mobile-line ht ${className ?? ""}`}>
      <div className="container">
        <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabContent} tabBarGutter={12} moreIcon={null} />
      </div>
    </section>
  </ConfigProvider>
}
export default TabSelectMovieMobile