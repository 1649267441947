/* eslint-disable @next/next/no-img-element */
import parse from 'html-react-parser';
import Link from "next/link";
export const NewsDetail = ({ res }: any) => {
  return (
    <div className="news-dt sc-pd">
      <div className="container">
        <h1 className="news-dt-title sub-tittle">{res?.title}</h1>
        <div className="news-dt-main">
          <div className="news-dt-side row">
            <div className="news-dt-left col">
              <div className="news-dt-content mona-content">
                {parse(res?.filtered_content || '')}

              </div>
            </div>
            {res?.related_posts?.length > 0 && (
              <div className="news-dt-right col">
                <div className="news-dt-sbar">
                  <p className="news-dt-sbar-title">
                    Tin tức liên quan
                  </p>
                  <div className="news-mn-list row">
                    {res?.related_posts?.map((item: any, index: number) => (
                      <div className="news-mn-item col" key={index}>
                        <div className="news-mn-img">
                          <Link href="" className="inner">
                            <img src={item.first_image} alt="" />
                          </Link>
                        </div>
                        <div className="news-mn-content">
                          <Link href="" className="news-mn-name">
                            {item.title}
                          </Link>
                          <p className="news-mn-des line-clamp-2">
                            {item.short_filtered_content}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </div>
  )
}