/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/router';
import careerApi from '@/services/career';
import FormCareer from '../FormCareer';
import { Career } from '@/types/career';
import { useQuery } from 'react-query';
import useContentGlobal from '@/hooks/useContentGlobal';

export const CareerDetailPage = () => {
  const router = useRouter();
  const urlKey = router.query?.urlKey as string ?? "";

  const { getValueByKey } = useContentGlobal();

  const { data } = useQuery<Career>(["career", urlKey], async () => {
    const res:any = await careerApi.getDetail(urlKey);
    return (res?.careersDetail?.items?.[0]);
  })

  const formatDateString = (input: string): string => {
    const inputDate = new Date(input);
  
    if (!isNaN(inputDate.getTime())) {
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1; 
      const year = inputDate.getFullYear();
  
      return `${day}/${month}/${year}`;
    } else {
      return input;
    }
  }
  return (
    <section className="career-detail ht">
        <div className="container"> 
          <div className="career-detail-wr"> 
            <div className="career-detail-heading" data-aos="fade-up">
              <div className="heading">
                <h1>{data?.title}</h1>
              </div>
              <div className="time"> <span className="icon"> <img src="/assets/images/ic-calendar.svg" alt=""/></span>
                <div className="sub-tittle"> <span className="tt">{getValueByKey(["Career_Page","Application_Deadline"])}: </span><span className="date">{formatDateString(data?.expired_at ?? "")}</span></div>
              </div>
            </div>
            <h4 className="txt-lg">{getValueByKey(["Career_Page","Job_Description"])}:</h4>
            <div className="career-detail-main row" data-aos="fade-up">
              <div className="career-detail-content col">
                {data?.cinema_name && <div className='career-detail-workplace'>{getValueByKey("positon") || "Nơi làm việc"}: {data?.cinema_name}</div>}
              <div dangerouslySetInnerHTML={{ __html: data?.requirement?.replaceAll('#000000',"#ffffff") ?? "" }} />
              </div>
              <div className="career-detail-form col">
                <div className="form-contact">
                  <FormCareer careerID={data?.entity_id ?? ""} careerTitle={data?.title ?? ""} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}