/* eslint-disable @next/next/no-img-element */
// import newsApi from '@/services/news';
import { Tooltip } from 'antd';
import parse from 'html-react-parser';
import Link from 'next/link';
import useContentGlobal from '@/hooks/useContentGlobal';

export const NewsPage = ({ listNews }: any) => {
  const { getValueByKey } = useContentGlobal();

  const leftData: any = listNews?.[0] ?? {};
  const rightData: any = listNews?.slice(1, 4) ?? [];

  const renderTopNews = {
    left: () => {
      return (
        <div className="news-item col col-6" data-aos="fade-up">
          <div className="news-inner">
            <div className="news-image">
              <Link className="image" href={'/news/news-detail/' + leftData?.identifier} rel="noreferrer">
                <img src={leftData.first_image} alt="" />
              </Link>
            </div>
            <div className="news-content">
              <Tooltip placement="bottom" title={leftData?.title} style={{ width: '1500px' }} overlayStyle={{ backgroundColor: 'black' }}>
                <Link className="sub-title" href={'/news/news-detail/' + leftData?.identifier} rel="noreferrer">
                  {leftData?.title}
                </Link>
              </Tooltip>
              <div className='line-clamp-3' >{parse(leftData?.short_filtered_content || '')}</div>
              <Link className="link-more" href={'/news/news-detail/' + leftData?.identifier} rel="noreferrer">{getValueByKey("Read_More")}</Link>
            </div>
          </div>
        </div>
      );
    },

    right: () => {
      return rightData?.map((post: TBlogPostItem) => {
        return (
          <div className="news-inner" key={post.post_id}>
            <div className="news-image">
              <Link className="image" href={'/news/news-detail/' + post?.identifier} rel="noreferrer">
                <img src={post.first_image} alt="" />
              </Link>
            </div>
            <div className="news-content">
              <Tooltip placement="bottom" title={post?.title} style={{ width: '1500px' }} overlayStyle={{ backgroundColor: 'black' }}>
                <Link className="sub-title" href={'/news/news-detail/' + post?.identifier} rel="noreferrer">{post.title}</Link>
              </Tooltip>
              <div className='line-clamp-2'>{parse(post?.short_filtered_content || '')} </div>
              <Link className="link-more" href={'/news/news-detail/' + post?.identifier} rel="noreferrer">{getValueByKey("Read_More")}</Link>
            </div>
          </div>
        );
      })
    },
  }

  return (
    <div className="news ht">
      <div className="container">
        <div className="news-wr">
          <div className="news-head sec-heading" data-aos="fade-up">
            <div className="heading">
              <h1>{getValueByKey(["News_Page", "Title"]) ?? "TIN TỨC MỚI NHẤT"}</h1>
            </div>
            <div className="desc">
              <p className="txt">{getValueByKey(["News_Page", "Desc"])}</p>
            </div>
          </div>
        </div>
      </div>
      <section className="film-news">
        <div className="container">
          <div className="film-news-heading sec-heading" data-aos="fade-up">
            <div className="heading">
              <h2>{getValueByKey(["News_Page", "Sub_Title"])}</h2>
            </div><Link className="link-more" href={'/news/news-category/tin-dien-anh'} rel='noreferrer'>{getValueByKey("View_All")}</Link>
          </div>
          <div className="film-news-content">
            <div className="news-list row">
              {renderTopNews.left()}
              <div className="news-item col col-6" data-aos="fade-up">
                {renderTopNews.right()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}