/* eslint-disable @next/next/no-img-element */
import { PopcornItem } from '@/components/screen'
import { LoadingCustom } from '@/components/screen/common/LoadingCustom'
import { handleSortCinemas, toggleLanguage } from '@/helper'
import useContentGlobal from '@/hooks/useContentGlobal'
import useSavaSessionKey from '@/hooks/useSavaSessionKey'
import { sendLog } from '@/services/sendLog'
import { _format } from '@/utils/format'
import { SortCinema } from '@/utils/sortCinema'
import { Select } from 'antd'
import router from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

type TProps = {
  dataCinema: any
  slider: any
}

export const PopcornDrinkPage = ({ dataCinema, slider }: TProps) => {
  const { getValueByKey } = useContentGlobal();
  const [cinema, setCinema] = useState<{ value: string, label: string }>({
    value: "0",
    label: getValueByKey("Select_Theater") ?? "Chọn rạp"
  })
  const [comboPick, setComboPick] = useState([])
  const [total, setTotal] = useState(0)
  const { session_key } = useSavaSessionKey()

  // Lấy danh sách rạp
  // const { data: dataCinema } = useQuery(["cinema"], async () => {
  //   const res = await fetch(`/api/cinema`).then(res => res.json()).catch((err) => {
  //     sendLog({
  //       action: "fetch_data_cinema",
  //       page_name: "PopcornDrinkPage",
  //       page_url: router.asPath,
  //       session_key: session_key,
  //       status: "fail",
  //       error_message: JSON.stringify(err),
  //       information: "Fetch data cinema fail",
  //       request_json: JSON.stringify({})
  //     })
  //   })
  //   return res?.data
  // })

  // Lấy combo
  const { data: comboData, isLoading: isLoadingCombo } = useQuery(["cinema", cinema], async () => {
    const res = await fetch(`/api/combo?id_Server=${cinema?.value}`).then(res => res.json()).catch((err) => {
      sendLog({
        action: "fetch_data_combo",
        page_name: "PopcornDrinkPage",
        page_url: router.asPath,
        session_key: session_key,
        status: "fail",
        error_message: JSON.stringify(err),
        information: "Fetch data combo fail",
        request_json: JSON.stringify({ cinema: cinema?.value })
      })
    })
    return res?.data
  }, { enabled: cinema.value != "0" })

  useEffect(() => {
    let total = 0
    comboPick?.map((item: any) => {
      total += parseInt(item?.price) * parseInt(item?.qty)
    })
    setTotal(total)
  }, [comboPick])

  const handleBooking = (id?: string) => {
    localStorage.removeItem("barCodeCombo")
    localStorage.removeItem("combo")

    let totalItem = 0
    const cinemaData = dataCinema?.find((item: any) => item?.id_server == cinema?.value)
    const itemBuyNow = comboPick?.filter((item: any) => item?.id == id)
    const listComboPick = id ? itemBuyNow : comboPick
    if (id) {
      listComboPick?.map((item: any) => {
        totalItem += parseInt(item?.price) * (parseInt(item?.qty) || 1)
      })
    }
    const dataEndcode = encodeURI(JSON.stringify({ comboPick: listComboPick, total: id ? totalItem : total, cinema: cinema, cinemaData: cinemaData }))
    localStorage.setItem("combo", dataEndcode)
    router.push(`/checkout?type=combo`)
  }
  const [listCinemaSorted, setListCinemaSorted] = useState<any[]>([])
  useEffect(() => {
    if (dataCinema?.length > 0) {
      SortCinema(dataCinema, setListCinemaSorted)
    }

  }, [dataCinema])
  useEffect(() => {
    setCinema({
      value: listCinemaSorted[0]?.id_server,
      label: toggleLanguage(listCinemaSorted[0], "name")
    })
  }, [listCinemaSorted])

  const renderDataCombo = () => {
    if (isLoadingCombo || listCinemaSorted?.length == 0) return <LoadingCustom classCustom={"--second"} />

    return comboData?.map((item: TCombo) => {
      return <Fragment key={item?.id_group + cinema?.value}>
        <section className="combo pb-[6rem]">
          <div className="container">
            <div className="combo-wr dt-food dt-food-second">
              <div className="combo-heading sec-heading" data-aos="fade-up">
                <div className="heading">
                  <h2>{item?.name_group}</h2>
                </div>
              </div>
              <div className="combo-content">
                <div className="combo-list row">
                  {item?.combo?.map((item: TComboItem) => {
                    return <Fragment key={item?.id}>
                      <PopcornItem
                        data={item}
                        comboPick={comboPick}
                        setComboPick={setComboPick}
                        handleBooking={() => handleBooking(item?.id)} />
                    </Fragment>
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    })
  }
  const banner = slider?.Slider?.items?.[0]?.Banner?.items?.[0]
  return (
    <>
      <section className="corn-drink promotion-banner ht">
        <div className="container">
          {banner?.media && <div className="promotion-banner-wr article-type-wr">
            <div className="bg"><img src={banner?.media ?? "/assets/images/img-corn-drink-banner.png"} alt="" />
            </div>
          </div>}
        </div>
      </section>
      <section className='pb-[8rem] bill-fixed-start'>
        <div className="container flex items-center flex-col gap-[1.6rem]" data-aos="fade-up">
          <div className="heading">
            <h1>{getValueByKey("Select_Nearest_Cinema") ?? "Chọn rạp gần bạn"}</h1>
          </div>
          <div className="select-location-second">
            {/* <img src="/assets/images/ic-branch-map.svg" alt="" /> */}
            <Select
              value={cinema}
              className='area-theater'
              onChange={(_, data: any) => {
                setCinema({
                  value: data?.value,
                  label: data?.label,
                })
                setComboPick([])
              }}
              options={handleSortCinemas(listCinemaSorted)?.map((item: any) => ({
                value: item?.id_server,
                label: toggleLanguage(item, "name"),
              }))}
            />
          </div>
        </div>
      </section>

      {renderDataCombo()}

      {comboPick && comboPick?.length > 0 &&
        <section className="bill pb-80 bill-fixed sticky">
          <div className="container">
            <div className="bill-wr">
              <div className="bill-left max-w-[60%]">
                <h4 className="name-combo sub-title">{cinema?.label} </h4>
                {/* <div className="name-branch">
                <p>{cinema?.label}</p>
              </div> */}
                <div className="detail sub-title ">
                  <p>{comboPick?.map((item: any) => `${item?.qty} ${item?.name}`)?.join("   |   ")}</p>
                </div>
              </div>
              <div className="bill-right">
                <div className="price">
                  <span className="txt">Tạm tính </span>
                  <span className="num">{_format.getVND(total)}</span>
                </div>
                {/* <a className="btn btn--pri">MUA KÈM VÉ XEM PHIM</a> */}
                <button onClick={() => handleBooking()} className="btn btn--white">THANH TOÁN</button>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}