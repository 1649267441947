/* eslint-disable @next/next/no-img-element */
import { FC } from 'react'
import Link from 'next/link'
import parse from 'html-react-parser'
import useContentGlobal from '@/hooks/useContentGlobal'

type PropsCardPost = {
  post: TBlogPostItem
}

const CardPost: FC<PropsCardPost> = ({post}) => {
  const { getValueByKey } = useContentGlobal();
  
  return (
    <div className="hot-news-item col col-4" data-aos="fade-up">
      <div className="news-inner">
        <div className="news-image">
          <Link className="image" href={'/news/news-detail/' + post?.identifier}  rel="noreferrer">
            <img src={post.first_image} alt="" />
          </Link>
        </div>
        <div className="news-content">
          <Link className="sub-title" href={'/news/news-detail/' + post?.identifier} rel="noreferrer">{post?.title}</Link>
          <div className='line-clamp-2'  > {parse(post?.short_filtered_content || '')}</div>
          <Link className="link-more" href={'/news/news-detail/'+post?.identifier} rel="noreferrer">{getValueByKey("Read_More")}</Link>
        </div>
      </div>
    </div>
  )
}

export default CardPost