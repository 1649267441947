/* eslint-disable @next/next/no-img-element */
import { MovieSection, NavigateBooking } from '@/components/screen'

import TabSelectMovieMobile from '@/components/tabMovieMobile'
import useContentGlobal from '@/hooks/useContentGlobal'
import { useWindowSize } from '@/hooks/userWindowSize'

import { TabsProps } from 'antd'


import React, { useEffect, useState } from 'react'

type TProps = {
  res: any
}

export const MoviePage: React.FC<TProps> = ({ res }) => {
  const [dataMovie, setDataMovie] = useState<{ mvShowing: TMovie[], mvSpecial: TMovie[], mvUpcoming: TMovie[], mvFilmFestival: TMovie[] }>()
  const { getValueByKey } = useContentGlobal();
  const {isDesktop} = useWindowSize()
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className="tabs-heading">
        <h1>{getValueByKey("Now_Showing") ?? 'Phim đang chiếu'}</h1>
      </div>,
      children: dataMovie?.mvShowing && dataMovie?.mvShowing?.length > 0 && <MovieSection movies={dataMovie?.mvShowing} isShowMore={true} isShowMoreUrl={"/movie/showing"} noTitle={true} title={getValueByKey("Now_Showing") ?? 'Phim đang chiếu'} />,
    },
    {
      key: '4',
      label: <div className="tabs-heading">
        <h1>{getValueByKey("Coming_Soon") ?? "Phim sắp chiếu"}</h1>
      </div>,
      children: dataMovie?.mvUpcoming && dataMovie?.mvUpcoming?.length > 0 && <MovieSection movies={dataMovie?.mvUpcoming} isShowMore={true} isShowMoreUrl={"/movie/upcoming"} noTitle={true} title={getValueByKey("Coming_Soon") ?? 'Phim sắp chiếu'} />,
    },
    {
      key: '2',
      label: <div className="tabs-heading">
        <h1>{getValueByKey("") ?? 'Suất chiết đặc biệt'}</h1>
      </div>,
      children: dataMovie?.mvSpecial && dataMovie?.mvSpecial?.length > 0 && <MovieSection movies={dataMovie?.mvSpecial} isShowMore={true} isShowMoreUrl={"/movie/special"} noTitle={true} title={getValueByKey("") ?? "Suất chiết đặc biệt"} />,
    },
    {
      key: '3',
      label: <div className="tabs-heading">
        <h1>{getValueByKey("") ?? "Liên hoan phim"}</h1>
      </div>,
      children: dataMovie?.mvFilmFestival && dataMovie?.mvFilmFestival.length > 0 && <MovieSection movies={dataMovie?.mvFilmFestival} isShowMore={true} isShowMoreUrl={"/movie/festival"} noTitle={true} title={getValueByKey("") ?? "Liên hoan phim"} />,
    },
  ];

  useEffect(() => {
    const mvShowing: TMovie[] = res?.res?.listMovie?.filter((mv: TMovie) => mv?.status == "2") // phim đang chiếu
    const mvSpecial: TMovie[] = res?.res?.listMovie?.filter((mv: TMovie) => mv?.status == "3") // phim đặc biệt
    const mvUpcoming: TMovie[] = res?.res?.listMovie?.filter((mv: TMovie) => mv?.status == "1") // phim sắp chiếu
    const mvFilmFestival: TMovie[] = res?.res?.listMovie?.filter((mv: TMovie) => mv?.status == "4") // liên hoan phim

    setDataMovie({
      mvShowing: mvShowing,
      mvSpecial: mvSpecial,
      mvUpcoming: mvUpcoming,
      mvFilmFestival: mvFilmFestival
    })
  }, [res?.res?.listMovie])

  return (
    <div className='flex flex-col'>
      <div className={`lg:order-1 order-2 ${!isDesktop ? "ht" : ""}`}><NavigateBooking listCinemas={res?.res?.listCinemas} /></div>
      {/* {!isDesktop && !isTablet && <SelectTheaterMobile isHideTitle idItems={router.asPath.split("/").at(-2)}/>} */}
      <div className='lg:order-2 order-1'>
        <div className='is-mobile'><TabSelectMovieMobile className='tabs-movie' tabContent={items.filter((item) => item.children)} /></div>
        <div className="is-desktop">
          {dataMovie?.mvShowing && dataMovie?.mvShowing?.length > 0 && <MovieSection movies={dataMovie?.mvShowing} isShowMore={true} isShowMoreUrl="/movie/showing" title={getValueByKey("Now_Showing") ?? "Phim đang chiếu"} isGrid={false} />}
          {dataMovie?.mvSpecial && dataMovie?.mvSpecial?.length > 0 && <MovieSection movies={dataMovie?.mvSpecial} isShowMore={true} isShowMoreUrl="/movie/special" title={getValueByKey("Special_Screening") ?? "Suất chiết đặc biệt"} isGrid={false} />}
          {/* Đợi kingpro up code fill sau */}
          {dataMovie?.mvFilmFestival && dataMovie?.mvFilmFestival?.length > 0 && <MovieSection movies={dataMovie?.mvFilmFestival} isShowMore={true} isShowMoreUrl="/movie/festival" title={getValueByKey("Film_Festival") ?? "Liên hoan phim"} isGrid={false} />}
          {/* Đợi kingpro up code fill sau */}
          {dataMovie?.mvUpcoming && dataMovie?.mvUpcoming?.length > 0 && <MovieSection movies={dataMovie?.mvUpcoming} isShowMore={true} isShowMoreUrl="/movie/upcoming" title={getValueByKey("Coming_Soon") ?? "Phim sắp chiếu"} isGrid={false} />}
        </div>
      </div>
    </div>
  )
}