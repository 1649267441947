/* eslint-disable @next/next/no-img-element */
import { FC, ReactNode, useRef } from 'react';
import { useClickOutside } from '@/hooks/useClickOutside';

type Props = {
  isOpen: boolean;
  children: ReactNode;
  onClose: (e?:any) => void;
}

const PopupCareer: FC<Props> = ({ children, isOpen, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside([ref], onClose);

  if (!isOpen) return null;
  return (
    <div
      className="form-checkout-cus block-check sideFixed"
      style={{
        opacity: 1,
        visibility: 'unset',
        padding: 0
      }}
      ref={ref} >
      <i className="fa-solid fa-xmark" style={{
        position: "absolute",
        right: "10px",
        top: "10px",
        cursor: "pointer",
        fontSize: "20px",
        color: "rgb(15, 23, 42)",
      }} onClick={onClose}></i>
      {children}
    </div>
  )
}

export default PopupCareer