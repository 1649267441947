import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'

export const blogPosts = gql`
	query blogPosts(
		$filter: BlogPostsFilterInput
		$pageSize: Int
		$currentPage: Int
		$sort: [String]
		$sortFiled: String
		$allPosts: Boolean
	) {
		blogPosts(
			filter: $filter
			pageSize: $pageSize
			currentPage: $currentPage
			sort: $sort
			sortFiled: $sortFiled
			allPosts: $allPosts
		) {
			items {
				author_id
				canonical_url
				categories {
					title
				}
				category_id
				creation_time
				end_time
				first_image
				identifier
				is_active
				position
				post_id
				post_url
				publish_time
				title
				type
				short_filtered_content
				views_count
			}
			total_count
			total_pages
		}
	}
`
export const getPostDetail = gql`
	query getPostDetail($id: String) {
		blogPost(id: $id) {
			author_id
			canonical_url
			categories {
				title
			}
			category_id
			creation_time
			end_time
			first_image
			identifier
			is_active
			position
			post_id
			post_url
			filtered_content
			publish_time
			title
			meta_description
			meta_title
			meta_keywords
			type
			short_filtered_content
			views_count
			related_posts {
				title
				content
				first_image
			}
		}
	}
`

type TInputBlogPosts = {
	filter?: any
	sort?: string[]
	pageSize?: number
	sortFiled?: string
	allPosts?: boolean
	currentPage?: number
}

const handleGetNews = async (input: TInputBlogPosts) => {
	const language = typeof window !== 'undefined' ? localStorage.getItem('language') ?? 'vn' : 'vn'
	graphQLClient.setHeader('store', language)

	return await graphQLClient.request(blogPosts, input)
}

const newsApi = {
	getNews: handleGetNews,
	getPostDetail: async (id: string) => {
		try {
			return await graphQLClient.request(getPostDetail, { id: id })
		} catch (error) {
			console.error('Error get list post:', error)
		}
	}
}

export default newsApi
