import { FC } from "react";

interface IPaginationNews {
  currentPage: number;
  totalPage: number;
  onPageChange: (page: number) => void;
}

const PaginationNews: FC<IPaginationNews> = ({ currentPage, totalPage, onPageChange }) => {
  const renderPaginationItems = () => {
    const items = [];
    const visiblePages = 5;

    if (totalPage <= visiblePages * 2 + 1) {
      for (let i = 1; i <= totalPage; i++) {
        items.push(
          <li key={i}>
            <button className={i === currentPage ? 'current page-numbers ' : 'page-numbers'}
              onClick={() => onPageChange(i)} >
              {i}
            </button>
          </li>
        );
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      let endPage = Math.min(totalPage, startPage + visiblePages - 1);

      if (currentPage - visiblePages > 1) {
        items.push(<li>
          <button className="page-numbers">
            ...
          </button>
        </li>);
      }

      for (let i = startPage; i <= endPage; i++) {
        items.push(
          <li key={i}>
            <button className={i === currentPage ? 'current page-numbers ' : 'page-numbers'}
              onClick={() => onPageChange(i)} >
              {i}
            </button>
          </li>
        );
      }

      if (currentPage + visiblePages < totalPage) {
        items.push(<li>
          <button className="page-numbers">
            ...
          </button>
        </li>);
      }
    }

    return items;
  };
  if (totalPage < 2) return <></>

  return (
    <ul className="page-numbers">
      <li>
        <button
          className={currentPage === 1 ? "prev page-numbers dot" : "prev page-numbers"}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}>
          <i className="fa fa-angle-left"></i>
        </button>
      </li>
      {renderPaginationItems()}
      <li>
        <button
          className={currentPage === totalPage ? "prev page-numbers dot" : "prev page-numbers"}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPage}>
          <i className="fa fa-angle-right"></i>
        </button>
      </li>
    </ul>
  );
};

export default PaginationNews