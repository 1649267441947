export * from "./home"
export * from "./movie"
export * from "./events"
export * from "./about"
export * from "./popcorn-drink"
export * from "./news"
export * from "./news/news-detail"
export * from "./news/news-category"
export * from "./login"
export * from "./contact"
export * from "./career"
export * from "./career/career-detail"
export * from "./showtimes"
