/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { SystemCinema } from '@/components/screen/about';
import { Fancybox } from '@fancyapps/ui';
import { useEffect } from 'react';

export const AboutPage = () => {
  useEffect(()=>{
    const galleryItem = document.querySelectorAll(".sec-gallery .gallery .gallery-item") as NodeListOf<HTMLDivElement>
    galleryItem.forEach((item)=>{
      Fancybox.bind(`[data-fancybox="gallery${item.id}"]`, {
        Toolbar: {
          display: {
            left: ["infobar"],
            middle: [
              "zoomIn",
              "zoomOut",
              "toggle1to1",
              "rotateCCW",
              "rotateCW",
              "flipX",
              "flipY",
            ],
            right: ["slideshow", "thumbs", "close"],
          },
        }
      });
    })
  }, [])
  return (
    <>
      <section className="sec-ht">
        <div className="ht">
          <div className="container"> 
            <div className="ht-qc" data-aos="fade-up">
              <div className="ht-qc-br"> <img src="/assets/images/ht-qc-br.jpg" alt=""/>
              </div>
              <div className="ht-qc-content"> 
                <h2 className="heading">HỆ THỐNG CỤM RẠP TRÊN TOÀN QUỐC</h2>
                <p className="des">Cinestar là một trong những hệ thống rạp chiếu phim được yêu thích nhất tại Việt Nam, cung cấp nhiều mô hình giải trí hấp dẫn bao gồm Các Cụm Rạp Chiếu Phim hiện đại, Nhà hát, Khu vui chơi trẻ em Kidzone, Bowling, Billiards, Games, Phòng Gym, Nhà Hàng, và Phố Bia C Beer. Với mục tiêu trở thành điểm đến giải trí cho mọi gia đình Việt Nam, Cinestar đang được biết đến là cụm rạp ủng hộ phim Việt, góp phần phát triển điện ảnh Việt. Không chỉ chiếu các bộ phim bom tấn quốc tế, Cinestar còn đồng hành cùng các nhà làm phim Việt Nam, đưa những tác phẩm điện ảnh đặc sắc của Việt Nam đến gần hơn với khán giả. </p>
              </div>
            </div>
            <div className="ht-as"> 
              <div className="ht-as-heading sec-heading" data-aos="fade-up">
                <h2 className="heading">SỨ MỆNH</h2>
              </div>
              <div className="ht-as-list row" data-aos="fade-up">
                <div className="ht-as-item col col-4">
                  <div className="ht-as-wr"> 
                    <p className="num">01</p>
                    <h3 className="ht-as-name">Góp phần tăng trưởng thị phần điện ảnh, văn hóa, giải trí của người Việt Nam</h3>
                  </div>
                </div>
                <div className="ht-as-item col col-4">
                  <div className="ht-as-wr"> 
                    <p className="num">02</p>
                    <h3 className="ht-as-name">Phát triển dịch vụ xuất sắc với mức giá tối ưu, phù hợp với thu nhập người Việt Nam.</h3>
                  </div>
                </div>
                <div className="ht-as-item col col-4">
                  <div className="ht-as-wr"> 
                    <p className="num">03</p>
                    <h3 className="ht-as-name">Mang nghệ thuật điện ảnh, văn hóa Việt Nam hội nhập quốc tế.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='sec-gallery'>
        <div className='ht'>
          <div className="container">
            <div className='gallery'>
              {[...Array(9)].map((_, index) => {
                return <div className='gallery-item' key={index} id={index.toString()}>
                  <div className="gallery-item-wrap">
                  <div className="gallery-item-images">
                    <a href="https://lipsum.app/id/32/1600x1200" data-fancybox={`gallery${index}`} className='gallery-item-image'>
                      <img src="https://lipsum.app/id/32/1600x1200" />
                    </a>
                    <a href="https://lipsum.app/id/32/1600x1200" data-fancybox={`gallery${index}`} className='gallery-item-image'>
                      <img src="https://lipsum.app/id/32/1600x1200" />
                    </a>
                  </div>
                  </div>
                  <div className="gallery-item-content">
                    <h2 className="heading">Cinestar Quốc Thanh</h2>
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="sec-ts"> 
        <div className="ts"> 
          <div className="container"> 
            <div className="sec-heading" data-aos="fade-up"> 
              <h2 className="heading">TRỤ SỞ CỦA CHÚNG TÔI</h2>
              <p className="des">Các phòng chiếu được trang bị các thiết bị tiên tiến như hệ thống âm thanh vòm, màn hình rộng và độ phân giải cao, tạo nên hình ảnh sắc nét và âm thanh sống động.</p>
            </div>
            <div className="ts-slider" data-aos="fade-up">
              <div className="swiper-container"> 
                <div className="swiper rows"> 
                  <div className="swiper-wrapper">  
                    <div className="swiper-slide col"> 
                      <div className="ts-wr"> 
                        <div className="ts-img"> <img className="ts-img-des" src="/assets/images/ts-img-2.jpg" alt="" /><img className="ts-img-mobile" src="/assets/images/ts-img-2-mobile.jpg" alt="" /></div>
                        <div className="ts-content"> 
                          <h3 className="ts-name heading">TRỤ SỞ | HEADQUARTER</h3>
                          <ul className="ct-tt"> 
                            <li><img src="/assets/images/ts-1.svg" alt=""/><a href="">135 Hai Bà Trưng, Phường Bến Nghé, Quận 1</a>
                            </li>
                            <li><img src="/assets/images/ts-2.svg" alt=""/><a href="">marketing.cinestar@gmail.com</a>
                            </li>
                            <li><img src="/assets/images/ts-3.svg" alt=""/><a href="">028 7300 7279</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide col"> 
                      <div className="ts-wr"> 
                        <div className="ts-img"> <img className="ts-img-des" src="/assets/images/ts-img-2.jpg" alt="" /><img className="ts-img-mobile" src="/assets/images/ts-img-2-mobile.jpg" alt="" /></div>
                        <div className="ts-content"> 
                          <h3 className="ts-name heading">TRỤ SỞ | HEADQUARTER</h3>
                          <ul className="ct-tt"> 
                            <li><img src="/assets/images/ts-1.svg" alt=""/><a href="">135 Hai Bà Trưng, Phường Bến Nghé, Quận 1</a>
                            </li>
                            <li><img src="/assets/images/ts-2.svg" alt=""/><a href="">marketing.cinestar@gmail.com</a>
                            </li>
                            <li><img src="/assets/images/ts-3.svg" alt=""/><a href="">028 7300 7279</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide col"> 
                      <div className="ts-wr"> 
                        <div className="ts-img"> <img className="ts-img-des" src="/assets/images/ts-img-2.jpg" alt="" /><img className="ts-img-mobile" src="/assets/images/ts-img-2-mobile.jpg" alt="" /></div>
                        <div className="ts-content"> 
                          <h3 className="ts-name heading">TRỤ SỞ | HEADQUARTER</h3>
                          <ul className="ct-tt"> 
                            <li><img src="/assets/images/ts-1.svg" alt=""/><a href="">135 Hai Bà Trưng, Phường Bến Nghé, Quận 1</a>
                            </li>
                            <li><img src="/assets/images/ts-2.svg" alt=""/><a href="">marketing.cinestar@gmail.com</a>
                            </li>
                            <li><img src="/assets/images/ts-3.svg" alt=""/><a href="">028 7300 7279</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SystemCinema />
      <section className="sec-service"> 
        <div className="technology">
          <div className="container">
            <div className="sec-heading" data-aos="fade-up">
              <h2 className="heading">SỞ HỮU CÔNG NGHỆ HÀNG ĐẦU</h2>
              <p className="des">Các phòng chiếu được trang bị các thiết bị tiên tiến như hệ thống âm thanh vòm, màn hình rộng và độ phân giải cao, tạo nên hình ảnh sắc nét và âm thanh sống động.</p>
            </div>
            <div className="technology-content"> 
              <div className="cinema-rental-list row"> 
                <div className="cinema-rental-item col col-6" data-aos="fade-up">
                  <div className="image"> <img src="/assets/images/img-technology0.jpg" alt=""/>
                    <div className="content-main">
                      <div className="address-box">
                        <div className="cinema-rental-heading">
                          <h4 className="sub-tittle">Công nghệ 2D</h4>
                        </div>
                        <div className="cinema-rental-body">
                          <p className="desc">Công nghệ 3D Digital (Kỹ thuật số 3 chiều) cho phép khán giả cảm nhận thêm chiều sâu của hình ảnh, giúp cho không gian điện ảnh trở nên sống động như không gian thực mà chúng ta đang sống.</p>
                        </div><a className="cinema-rental-link" href=""><span className="txt">Trải nghiệm ngay</span><span className="ic ani-f"><img src="/assets/images/ic-arr-right.svg" alt=""/></span><span className="ic ani-s"><img src="/assets/images/ic-arr-right.svg" alt=""/></span></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cinema-rental-item col col-6" data-aos="fade-up">
                  <div className="image"> <img src="/assets/images/img-technology1.jpg" alt=""/>
                    <div className="content-main">
                      <div className="address-box">
                        <div className="cinema-rental-heading">
                          <h4 className="sub-tittle">Công nghệ 3D</h4>
                        </div>
                        <div className="cinema-rental-body">
                          <p className="desc">Công nghệ 3D Digital (Kỹ thuật số 3 chiều) cho phép khán giả cảm nhận thêm chiều sâu của hình ảnh, giúp cho không gian điện ảnh trở nên sống động như không gian thực mà chúng ta đang sống.</p>
                        </div><a className="cinema-rental-link" href=""><span className="txt">Trải nghiệm ngay</span><span className="ic ani-f"><img src="/assets/images/ic-arr-right.svg" alt=""/></span><span className="ic ani-s"><img src="/assets/images/ic-arr-right.svg" alt=""/></span></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cinema-rental-item col col-6" data-aos="fade-up">
                  <div className="image"> <img src="/assets/images/img-technology2.jpg" alt=""/>
                    <div className="content-main">
                      <div className="address-box">
                        <div className="cinema-rental-heading">
                          <h4 className="sub-tittle">Dolby Atmos</h4>
                        </div>
                        <div className="cinema-rental-body">
                          <p className="desc">Công nghệ 3D Digital (Kỹ thuật số 3 chiều) cho phép khán giả cảm nhận thêm chiều sâu của hình ảnh, giúp cho không gian điện ảnh trở nên sống động như không gian thực mà chúng ta đang sống.</p>
                        </div><a className="cinema-rental-link" href=""><span className="txt">Trải nghiệm ngay</span><span className="ic ani-f"><img src="/assets/images/ic-arr-right.svg" alt=""/></span><span className="ic ani-s"><img src="/assets/images/ic-arr-right.svg" alt=""/></span></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cinema-rental-item col col-6" data-aos="fade-up">
                  <div className="image"> <img src="/assets/images/img-technology3.jpg" alt=""/>
                    <div className="content-main">
                      <div className="address-box">
                        <div className="cinema-rental-heading">
                          <h4 className="sub-tittle">Máy chiếu Christie</h4>
                        </div>
                        <div className="cinema-rental-body">
                          <p className="desc">Công nghệ 3D Digital (Kỹ thuật số 3 chiều) cho phép khán giả cảm nhận thêm chiều sâu của hình ảnh, giúp cho không gian điện ảnh trở nên sống động như không gian thực mà chúng ta đang sống.</p>
                        </div><a className="cinema-rental-link" href=""><span className="txt">Trải nghiệm ngay</span><span className="ic ani-f"><img src="/assets/images/ic-arr-right.svg" alt=""/></span><span className="ic ani-s"><img src="/assets/images/ic-arr-right.svg" alt=""/></span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}