/* eslint-disable @next/next/no-img-element */
import { PopupNoti } from '@/components/screen/common/PopupNoti';
import SelectTheaterMobile from '@/components/selectTheaterMobile';
import { toggleLanguage } from '@/helper';
import useContentGlobal from '@/hooks/useContentGlobal';
import useStatePopupNotify from '@/hooks/useStatePopupNotify';
import careerApi from '@/services/career';
import { Career } from '@/types/career';
import { collapseAction, collapseInit } from '@/utils/CollapseConfig';
import parser from 'html-react-parser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { useQuery } from 'react-query';
import FormCareer from './FormCareer';
import PopupCareer from './PopupCareer';

type TProps = {
  dataProp: {
    bannerCms: {
      bannerCms_vn: string
      bannerCms_en: string
    }
  }
}

export const CareerPage = ({ dataProp }: TProps) => {
  const [careerSelected, setCareerSelected] = useState<Career | null>(null);
  const { popupNotify, handleOpenNotify, handleClosedNotify } = useStatePopupNotify();
  const [location, setLocation] = useState<TCinima | undefined>()
  const { getValueByKey } = useContentGlobal();

  const router = useRouter();

  const { data } = useQuery(["career"], () => careerApi.getList().then((res: any) => res), {
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    collapseInit();
  }, []);
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleRedirect = (urlKey: string) => {
    router.push({
      pathname: "/career/" + urlKey,
    })
  }

  const handleChangeLocation = (location: TCinima) => {
    setLocation(location)
  }

  const clearFilter = () => {
    setLocation(undefined)
  }

  const renderCareer = (careers: Career[]) => {
    const list = location ? careers?.filter(el => el?.cinema_name?.includes(toggleLanguage(location, "name"))) : careers
    if (list?.length < 1) return <div className='text-center'>{getValueByKey("recruitment") || "Không có thông tin tuyển dụng"}</div>
    return list.map((career) => {
      return (
        <div className="career-item collapseItem" data-aos="fade-up" key={career.entity_id}>
          <div className="career-item-heading collapseHead">
            <h4 className="sub-tittle">{career.title}</h4><i className="fa-solid fa-angle-down icon"></i>
          </div>
          <div className="career-item-body collapseBody">
            <div className="content">
              <div className="status">
                <span className="txt">{getValueByKey(["Career_Page", "Status"])}: </span>
                <span className="txt update">{getValueByKey(["Career_Page", "Recruiting"])} </span>
              </div>
              <div className="desc" dangerouslySetInnerHTML={{ __html: career?.cinema_name ?? "" }}>
              </div>
            </div>
            <div className="btn-group">
              <button className="btn btn--pri btn-submit" onClick={() => setCareerSelected(career)}>{getValueByKey(["Career_Page", "Apply_Button"])}</button>
              <button className="btn btn--outline btn-submit" onClick={() => handleRedirect(career.url_key)}>{getValueByKey("See_Details")}</button>
            </div>
          </div>
        </div>
      );
    });
  }

  const renderCareers = () => {
    return data?.careerCategories.items.map((item: any) => {
      if (item?.careers?.length < 1) return null
      return (
        <section className="career-content career-content-1 pb-80" key={item.entity_id}>
          <div className="container">
            <div className="career-content-wr">
              <div className="career-heading sec-heading" data-aos="fade-up">
                <div className="heading flex items-center">
                  <h2 className='flex-1'>{item.name}</h2>
                </div>
                <div className="desc">
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
              <div className="career-list collapseBlockJS" onClick={(e) => collapseAction(e)}>
                {renderCareer(item.careers)}
              </div>
            </div>
          </div>
        </section>
      );
    });
  }

  const handleSendFormSubmit = (message: string) => {
    setCareerSelected(null);
    handleOpenNotify(message);
  }
  return (
    <div className="career">
      {
        parser(isClient ? toggleLanguage(dataProp?.bannerCms, "bannerCms"): dataProp?.bannerCms?.bannerCms_en)
      }
      {/* <section className="career-banner ht">
        <div className="career-heading sec-heading remove-margin-top" data-aos="fade-up">
          <div className="heading">
            <h1>{getValueByKey(["Career_Page", "Title"])}</h1>
          </div>
          <div className="desc">
            <p className="txt">{getValueByKey(["Career_Page", "Desc"])}</p>
          </div>
        </div>
        <div className="career-banner-wr" data-aos="fade-up">
          <div className="career-banner-list">
            <div className="career-banner-item">
              <div className="image"><img src="/assets/images/img-career-banner-1.jpg" alt="" />
              </div>
            </div>
            <div className="career-banner-item">
              <div className="image"><img src="/assets/images/img-career-banner-2.jpg" alt="" />
              </div>
            </div>
            <div className="career-banner-item">
              <div className="image"><img src="/assets/images/img-career-banner-3.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {data?.careerCategories.items?.length > 0 && <div className='container filter-career-wrapper'><FilterLocation clearFilter={clearFilter} location={location} onChangeLocation={handleChangeLocation} /></div>}
      {renderCareers()}

      <PopupNoti
        isOpen={popupNotify.isOpen}
        handleClose={handleClosedNotify}
        content={popupNotify.message} />

      {careerSelected !== null ? (
        <PopupCareer isOpen onClose={(e) => {
          const element = e.target as HTMLDivElement
          const parentElement = element?.parentElement
          const isOptionSelect = element?.getAttribute("role") ?? parentElement?.getAttribute("role")
          isOptionSelect != "option" && setCareerSelected(null)
        }}>
          <FormCareer
            isPopup
            careerID={careerSelected?.entity_id ?? ""}
            careerTitle={careerSelected?.title ?? ""}
            onSuccess={() => handleSendFormSubmit(getValueByKey(["Message", "Success", "Apply_Job"]) ?? 'Gửi ứng tuyển thành công!')}
            onFailure={() => handleSendFormSubmit(getValueByKey(["Message", "Error", "Apply_Job"]) ?? 'Gửi ứng tuyển không thành công!')} />
        </PopupCareer>
      ) : null}

    </div>
  )
}

export default CareerPage;

const FilterLocation = ({ onChangeLocation, location, clearFilter }: { onChangeLocation: Function, location: TCinima | undefined, clearFilter: Function }) => {
  const handleGetName = (cinema: any) => {
    const alias = toggleLanguage(cinema, "alias");
    if (alias) return alias;

    return toggleLanguage(cinema, "name");
  }
  const valLocation = {
    value: location?.id,
    label: handleGetName(location)
  }


  return <div className='flex items-center justify-end'>
    {location?.id && <div className='filter-career' onClick={() => clearFilter()}><IoMdCloseCircle /></div>}
    <div className='select-location-header filter-career-select'><SelectTheaterMobile customClassWrap="booking-cinestar-heading flex justify-end items-center" value={valLocation} customOnChange={onChangeLocation} placeholder={"Chọn rạp"} customPopup={{ popupclassname: "select-localtion-header__popup", poppopupmatchselectwidth: false }} isHideTitle />
    </div>

  </div>
}