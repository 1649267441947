/* eslint-disable @next/next/no-img-element */
import newsApi from '@/services/news'
import { useState } from 'react'
import { useQuery } from 'react-query'
import PaginationNews from '../PaginationNews'
import CardPost from '../CardPost'


export const NewsCategories = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data } = useQuery({
    queryKey: ['news', currentPage],
    queryFn: () => newsApi.getNews({
      filter: {},
      pageSize: 20,
      currentPage,
      sort: [
        "DESC"
      ],
      sortFiled: "publish_time",
      allPosts: false
    }) as any
  })
  const renderBlogPosts = (blogPosts: TBlogPostItem[]) => {
    return blogPosts?.map((post: TBlogPostItem) => {
      return (
        <CardPost key={post?.post_id} post={post} />
      );
    });
  }
  return (
    <section className="hot-news pt-80">
      <div className="container">
        <div className="hot-news-heading sec-heading" data-aos="fade-up">
          <div className="heading">
            <h2>tin điện ảnh</h2>
          </div>
        </div>
        <div className="hot-news-content">
          <div className="hot-news-list row">
            {renderBlogPosts(data?.blogPosts?.items ?? [])}
          </div>
        </div>
        <PaginationNews currentPage={currentPage} totalPage={data?.blogPosts?.total_pages ?? 1} onPageChange={setCurrentPage} />
      </div>
    </section>
  )
}